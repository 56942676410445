import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2024,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.August,
    year: 2024,
    offers: [
      {
        title: 'FeniHydrocort 0,5%',
        description: 'bei mäßig entzündlichen Hauterkrankungen, 15g Creme',
        imgFile: 'Fenhydrocort_Creme_0_5.png',
        offerPrice: 7.99,
      },
      {
        title: 'Magnesium Verla',
        description: 'zur Magnesiumtherapie, 100 Filmtabletten',
        imgFile: 'magnesium_verla_200.png',
        offerPrice: 7.99,
      },
      {
        title: 'Vitamin B-Komplex',
        description: 'Nahrungsergänzungsmittel, 60 Hartkapseln',
        imgFile: 'ratiopharm Vitamin-B-Komplex.png',
        offerPrice: 15.99,
      },
    ],
  },
  {
    month: Month.September,
    year: 2024,
    offers: [
      {
        title: 'Voltaren',
        description: 'Schmerzgel, 120 g Gel',
        imgFile: 'voltaren-schmerzgel_ohne_gewicht.png',
        offerPrice: 14.99,
      },
      {
        title: 'Sinupret extrakt',
        description: 'bei Sinusitis, 20 Tabletten',
        imgFile: 'sinupret-extract.png',
        offerPrice: 12.99,
      },
      {
        title: 'Aspirin complex',
        description: 'bei Erkältung, 10 Beutel',
        imgFile: 'aspirin-complex.png',
        offerPrice: 8.99,
      },
    ],
  },
];

export default offersCollection;
